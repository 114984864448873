<template>
    <div
        class="container mb-3"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
        }"
    >
        <div class="d-flex flex-row align-items-center" style="gap: 0px">
            <BackButton />
            <h1 v-if="currentYacht" class="m-0 text-light">
                <span v-if="currentYacht.brand">
                    {{ currentYacht.brand.name }}
                </span>
                {{ currentYacht.identity.name }}
            </h1>
            <h1 class="m-0 text-light" v-else>
                {{ t('pages.viewYacht.notFound') }}
            </h1>
        </div>
    </div>
    <div
        v-if="currentYacht"
        :style="{ 'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM }"
    >
        <!-- If there is main image, show the image-->
        <img
            v-if="currentYacht.mainImage"
            class="cover-image mb-4"
            :src="
                GLOBAL_VAR_BACKEND_STORAGE_URL + currentYacht.mainImage.filename
            "
        />
        <!-- If there is no main image, show placeholder -->
        <img
            v-if="!currentYacht.mainImage"
            class="cover-image mb-4"
            :src="
                GLOBAL_VAR_BACKEND_STORAGE_URL +
                '/static-img/boat-placeholder.png'
            "
        />
        <div class="container">
            <div v-if="currentYacht.canBook">
                <router-link
                    v-if="currentYacht.captain"
                    :to="'/bookings/book/yacht/' + currentYacht.itself.id"
                    class="btn btn-primary mb-4 w-100"
                    >{{ t('pages.viewYacht.chooseBooking') }}</router-link
                >
                <div
                    v-else
                    class="btn mb-4 w-100 text-light"
                    style="background: #22313fd4"
                >
                    {{ t('misc.cannotBookNoCaptain') }}
                </div>
            </div>
            <div
                class="mb-4 text-center text-light rounded-pill px-3 py-2"
                style="background: #22313fd4"
                v-else
            >
                <h6 class="m-0" style="font-size: 1rem">
                    {{ t('pages.viewYacht.notSubscribed') }}
                </h6>
            </div>
            <!-- <CreditDisplay
                v-if="currentYacht.canBook"
                :current="currentYacht.tokens.current_tokens"
                :baseline="currentYacht.tokens.baseline_tokens"
                class="mb-3"
            /> -->
            <div class="text-dark mb-4">
                <div
                    v-if="currentYacht.mainBase"
                    class="d-inline-block me-3 yacht-item-desc"
                >
                    <img
                        src="@/assets/yacht-desc-icons/port.png"
                        style="width: 1.1rem"
                        class="me-1"
                    /><span class="text-upper">{{
                        currentYacht.mainBase.name
                    }}</span>
                </div>
                <div
                    v-if="currentYacht.sizeAndWeight.length"
                    class="d-inline-block me-3 yacht-item-desc"
                >
                    <img
                        src="@/assets/yacht-desc-icons/tenet.svg"
                        class="me-1"
                    />{{ currentYacht.sizeAndWeight.length }}
                </div>
                <div
                    v-if="currentYacht.identity.cabins"
                    class="d-inline-block me-3 yacht-item-desc"
                >
                    <img
                        src="@/assets/yacht-desc-icons/beds.svg"
                        class="me-1"
                    />{{ currentYacht.identity.cabins }}
                    {{ t('yachtItem.cabins') }}
                </div>
                <div
                    v-if="currentYacht.identity.passengers"
                    class="d-inline-block yacht-item-desc"
                >
                    <img
                        src="@/assets/yacht-desc-icons/guests.svg"
                        class="me-1"
                    />{{ currentYacht.identity.passengers }}
                    {{ t('yachtItem.passengers') }}
                </div>
            </div>
            <read-more
                v-if="
                    i18n.locale.value == 'en' &&
                    currentYacht.identity.description_en
                "
                class="mb-5"
                :more-str="t('pages.viewYacht.readMore')"
                :less-str="t('pages.viewYacht.readLess')"
                :text="currentYacht.identity.description_en"
                :max-chars="500"
            />
            <read-more
                v-if="
                    i18n.locale.value == 'fr' &&
                    currentYacht.identity.description_fr
                "
                class="mb-5"
                :more-str="t('pages.viewYacht.readMore')"
                :less-str="t('pages.viewYacht.readLess')"
                :text="currentYacht.identity.description_fr"
                :max-chars="500"
            />
            <!-- <div
                class="mb-5 d-inline-block rounded px-2"
                style="background: #a9986720"
            >
                <a href="#">
                    <i class="fa fa-plus" style="font-size: 0.9rem"></i>
                    Read more
                </a>
            </div> -->
            <div class="mb-5">
                <h5 class="mb-3">{{ t('pages.viewYacht.documents') }}</h5>
                <ul
                    v-if="currentYacht.documents.length"
                    class="list-group bg-light"
                >
                    <a
                        v-for="yachtDoc in currentYacht.documents"
                        :href="
                            GLOBAL_VAR_BACKEND_STORAGE_URL + yachtDoc.filename
                        "
                        target="_blank"
                        :key="yachtDoc.id"
                        class="list-group-item d-flex justify-content-between align-items-center tap-react"
                    >
                        <div
                            class="d-flex flex-row align-items-center"
                            style="gap: 0.7rem"
                        >
                            <img
                                src="@/assets/pdf-icon.png"
                                style="height: 35px"
                                alt="PDF File"
                            />
                            {{ yachtDoc.name }}
                        </div>
                        <i class="fa-solid fa-chevron-right text-muted"></i>
                    </a>
                </ul>
                <span v-else>{{ t('pages.viewYacht.notSpecified') }}</span>
            </div>
            <div class="mb-5">
                <h5 class="mb-3">{{ t('pages.viewYacht.engines') }}</h5>
                <div v-if="currentYacht.engines.length">
                    <Flicking
                        :options="{
                            horizontal: true,
                            align: 'prev',
                            moveType: 'freeScroll',
                            resizeOnContentsReady: true,
                        }"
                    >
                        <div
                            v-for="(engine, index) in currentYacht.engines"
                            :key="engine.id"
                            class="card rounded-4 p-3 me-4"
                        >
                            <h6>
                                <img
                                    src="@/assets/engine-icon.png"
                                    style="width: 1.5rem; opacity: 0.7"
                                />
                                {{ t('misc.engine') }} {{ index + 1 }}
                            </h6>
                            {{ engine.brand }} {{ engine.model }}<br />
                            {{ engine.horse_power }} {{ t('misc.horsepower')
                            }}<br />
                            {{ engine.transmission }} {{ t('misc.transmission')
                            }}<br />
                            {{ engine.propeller_material }}
                            {{ t('misc.propellers') }}<br />
                        </div>
                    </Flicking>
                </div>
                <span v-else>{{ t('pages.viewYacht.notSpecified') }}</span>
            </div>
            <div class="mb-5">
                <h5 class="mb-3">{{ t('pages.viewYacht.flag') }}</h5>
                <div
                    class="d-flex flex-row align-items-center"
                    v-if="currentYacht.identity.flag"
                >
                    <div
                        :class="`fi fi-${currentYacht.identity.flag}`"
                        class="me-2"
                        style="
                            height: 20px;
                            width: 37px;
                            background-size: 27px 37px;
                        "
                    ></div>
                    {{ flagName }}
                </div>
                <span v-else>{{ t('pages.viewYacht.notSpecified') }}</span>
            </div>
            <div class="mb-5">
                <h5 class="mb-3">{{ t('pages.viewYacht.mapLocation') }}</h5>
                <div
                    v-if="
                        currentYacht.localisation.usual_location &&
                        currentYacht.localisation.usual_location != ''
                    "
                    id="map"
                    style="height: 30vw; min-height: 20rem"
                    class="rounded-4"
                ></div>
                <div v-else>
                    {{ t('pages.viewYacht.notSpecified') }}
                </div>
            </div>
            <h5 class="mb-3">{{ t('pages.viewYacht.specifications') }}</h5>
            <div class="row mb-3">
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.location') }}
                    </p>
                    <div v-if="!currentYacht.mainBase">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.mainBase.name }}
                    </div>
                </div>
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.length') }}
                    </p>
                    <div v-if="!currentYacht.sizeAndWeight.length">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.sizeAndWeight.length }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.yearOfManufacture') }}
                    </p>
                    <div v-if="!currentYacht.identity.year_of_manufacture">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.identity.year_of_manufacture }}
                    </div>
                </div>
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.beam') }}
                    </p>
                    <div v-if="!currentYacht.sizeAndWeight.width">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.sizeAndWeight.width }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.weight') }}
                    </p>
                    <div v-if="!currentYacht.sizeAndWeight.weight">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.sizeAndWeight.weight }}
                    </div>
                </div>
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.hullLength') }}
                    </p>
                    <div v-if="!currentYacht.sizeAndWeight.hull_length">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.sizeAndWeight.hull_length }}
                    </div>
                </div>
            </div>
            <div class="row mb-3 spec">
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.hullMaterial') }}
                    </p>
                    <div v-if="!currentYacht.sizeAndWeight.hull_material">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.sizeAndWeight.hull_material }}
                    </div>
                </div>
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.width') }}
                    </p>
                    <div v-if="!currentYacht.sizeAndWeight.width">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.sizeAndWeight.width }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.navigationCategory') }}
                    </p>
                    <div v-if="!currentYacht.sizeAndWeight.navigation_category">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.sizeAndWeight.navigation_category }}
                    </div>
                </div>
                <div class="col">
                    <p class="m-0" style="font-weight: 500">
                        {{ t('pages.viewYacht.fuelCapacity') }}
                    </p>
                    <div v-if="!currentYacht.sizeAndWeight.fuel_capacity">
                        {{ t('pages.viewYacht.notSpecified') }}
                    </div>
                    <div v-else>
                        {{ currentYacht.sizeAndWeight.fuel_capacity }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="container">
        <div class="card p-3">
            <h5>{{ t('pages.viewYacht.yachtNotFound') }}</h5>
            <p>
                {{ t('pages.viewYacht.notFoundDesc') }}
            </p>
            <button @click="router.back()" class="btn btn-primary">
                {{ t('misc.goBack') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import Flicking from '@egjs/vue3-flicking';
import { flagResolve } from '@/flagResolve';
import '@egjs/vue3-flicking/dist/flicking.css';
import { useRouter, useRoute } from 'vue-router';
import { computed, onMounted, inject } from 'vue';
import { useYachtsStore } from '@/stores/yachts-store';

import BackButton from '@/components/BackButton';
// import CreditDisplay from '@/components/CreditDisplay.vue';

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const GLOBAL_VAR_BACKEND_STORAGE_URL = inject('GLOBAL_VAR_BACKEND_STORAGE_URL');

const i18n = useI18n();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const yachtsStore = useYachtsStore();
yachtsStore.fetchYachts();
const { yachts } = storeToRefs(yachtsStore);

const currentYacht = computed(() =>
    yachts.value.find((yacht) => yacht.itself.id == route.params.id)
);

const flagName = computed(() => flagResolve(currentYacht.value.identity.flag));

onMounted(() => {
    if (currentYacht.value) {
        if (
            currentYacht.value.identity.description_en ||
            currentYacht.value.identity.description_fr
        ) {
            const readmore = document.querySelector('#readmore');
            readmore.classList.add('show-plus');
        }

        // Map location
        if (
            currentYacht.value.localisation.usual_location &&
            currentYacht.value.localisation.usual_location != ''
        ) {
            delete L.Icon.Default.prototype._getIconUrl;
            L.Icon.Default.mergeOptions({
                iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
                iconUrl: require('leaflet/dist/images/marker-icon.png'),
                shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
            });
            const rawLatLng =
                currentYacht.value.localisation.usual_location.split(',');
            const latLng = new L.latLng(rawLatLng[0], rawLatLng[1]);
            const map = L.map('map', {
                attributionControl: false,
            }).setView(latLng, 14);
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
            }).addTo(map);

            L.marker(latLng).addTo(map);
        }
    }
});
</script>

<style scoped>
.cover-image {
    width: 100%;
    object-fit: cover;
    height: 35vh;
}

@media (min-width: 768px) {
    .cover-image {
        height: 50vh;
    }
}

@media (min-width: 992px) {
    .cover-image {
        height: 65vh;
    }
}
</style>

<style>
#readmore {
    font-weight: 500;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    border-radius: var(--bs-border-radius);
    background-color: #a9986720;
}

.show-plus::before {
    font-family: 'FontAwesome';
    content: '\2b';
    margin-right: 0.25rem;
    font-size: 0.8rem;
}

.yacht-item-desc {
    font-size: 0.9rem;
}
</style>
